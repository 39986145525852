import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../components/homeComp/commonComp/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { getUserData, signup } from "../api/Api";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function Register() {
  const navigate = useNavigate();
  const tokken = useSelector((state) => state.userData.token);
  useEffect(() => {
    if (tokken !== "") {
      navigate("/user/dashboard");
    }
  }, [tokken, navigate]);

  const [credentials, setCredentials] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleCheckboxChange = (e) => {
    setAgreeTerms(e.target.checked);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      toast.error("Please complete the CAPTCHA to proceed.");
      return;
    }
    if (!agreeTerms) {
      toast.error("You must agree to the terms and conditions to proceed.");
      return;
    }
    if (credentials.password.length < 8) {
      toast.error("Password must be at least 8 digits long.");
      return;
    }
    setLoading(true);
    try {
      const result = await signup({ ...credentials, captchaToken });
      if (result && result.status === "success") {
        const userData = await getUserData(result.data);
        const token = await result.data;
        toast.info("Enter Otp To Continue!");
        setTimeout(() => {
          navigate("/register/otp", {
            state: { otp: result.otp, token: token, userData: userData.data },
          });
        }, 1000);
      } else {
        toast.error(result.message || "Failed to Sign Up.");
      }
    } catch (error) {
      toast.error("An error occurred during registration.");
    } finally {
      setLoading(false);
    }
  };
  function togglePassword() {
    setShowPassword(!showPassword);
  }
  return (
    <>
      <Spinner />
      <Helmet>
        <title>Wemonitize4u Register Now</title>
        <meta
          name="description"
          content="A Wide Range of Services to Meet your Asset Conversion."
        />
        <meta
          name="keywords"
          content="Bitcoin, Money, Assets, Crypto, Monitize, Conversion, Currency, USD, AED, EUR"
        />
      </Helmet>
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-md-6 col-lg-6 col-sm-12 d-none d-md-block">
            <div className="text-center d-flex justify-content-center align-items-center">
              <img
                src="/assets/img/signupp.png"
                alt="icon"
                className="h-75 w-75"
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-sm-12">
            <div className="d-flex justify-content-center align-items-center">
              <div className="card w-75 mt-4">
                <div className="card-body">
                  <div className="text-center">
                    <Link to="/">
                      <img
                        src="/assets/img/logo.png"
                        alt="icon"
                        className=" logo-img"
                        width={150}
                      />
                    </Link>
                  </div>
                  <h1
                    className="card-subtitle text-center mb-4 display-4 fw-semibold"
                    style={{ color: "#16D5FF" }}
                  >
                    SignUp
                  </h1>
                  <form onSubmit={handleSignup}>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="name-reg"
                        placeholder="Full Name"
                        name="fullName"
                        value={credentials.fullName}
                        onChange={onChange}
                        required
                      />
                      <label htmlFor="name-reg">Full Name</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="email-reg"
                        placeholder="Email"
                        name="email"
                        value={credentials.email}
                        onChange={onChange}
                        required
                      />
                      <label htmlFor="email-reg">Email</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="password"
                        className="form-control"
                        id="password-reg"
                        placeholder="Password"
                        name="password"
                        value={credentials.password}
                        onChange={onChange}
                        required
                      />
                      <span
                        onClick={togglePassword}
                        className="password-toggle-icon"
                        aria-label={
                          showPassword ? "Hide password" : "Show password"
                        }
                      >
                        <i
                          className={`fa ${
                            showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                          aria-hidden="true"
                        ></i>
                      </span>
                      <label htmlFor="password-reg">Password</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="password"
                        className="form-control"
                        id="confpass-reg"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={credentials.confirmPassword}
                        onChange={onChange}
                        required
                      />
                      <label htmlFor="confpass-reg">Confirm Password</label>
                    </div>

                    <div className="mb-3">
                      <input
                        type="checkbox"
                        id="agreeTerms"
                        checked={agreeTerms}
                        onChange={handleCheckboxChange}
                        required
                      />
                      <label htmlFor="agreeTerms" className="ms-2">
                        I agree to the terms and conditions
                      </label>
                    </div>

                    <div className="mb-3 d-flex justify-content-center">
                      <ReCAPTCHA
                        sitekey="6Ld9bEwqAAAAAINfqatK5X9GcBOqRB5MMlWRm6pr" //API KEY
                        onChange={handleCaptchaChange}
                      />
                    </div>

                    {/* 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI Dummy Key */}
                    <div className="btn-regg">
                      <button
                        className="btn btn-block w-100 p-2"
                        type="submit"
                        style={{ backgroundColor: "#16D5FF" }}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          <span className="text-white fw-semibold">
                            Register
                          </span>
                        )}
                      </button>
                    </div>
                    <div>
                      <p className="mt-4">
                        Already have an Account?{" "}
                        <Link
                          className="btn-link text-info ms-auto fw-semibold"
                          to="/login"
                          style={{ textDecoration: "none" }}
                        >
                          SignIn
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
