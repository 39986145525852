import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Header from "../common/Header";
import ValueTable from "./ValueTable";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getallValueByType } from "../../../api/Api";
import { toast } from "react-toastify";
import TransationModel from "./TransationModel";

function Value() {
  const [cryptoData, setCryptoData] = useState([]);
  const [fiatData, setFiatData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null); 

  const fetchDataByType = async (type) => {
    setLoading(true);
    try {
      const res = await getallValueByType(type);
      if (res.status === "success") {
        if (type === "Crypto") {
          setCryptoData(res.data);
        } else if (type === "Fiat") {
          setFiatData(res.data);
        }
      } else {
        console.log(`Failed to fetch ${type} data.`);
      }
    } catch (error) {
      toast.error(`An error occurred while fetching ${type} data.`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataByType("Crypto");
    fetchDataByType("Fiat");
  }, []);

  const getSliderSettings = (dataLength) => ({
    dots: false,
    infinite: false, 
    speed: 500,
    slidesToShow: Math.min(dataLength, 7), 
    slidesToScroll: dataLength === 7 ? 0 : 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(dataLength, 4),
          slidesToScroll: dataLength === 7 ? 0 : 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(dataLength, 3),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const handleImageClick = (client) => {
    setSelectedData(client); 
  };

  return (
    <>
      <Header title="Value Unlock" />
      {!loading ? (
        <div className="container mt-5 display-flex justify-content-center">
          <h4>Crypto Currency</h4>
          {cryptoData.length > 0 ? (
            <Slider {...getSliderSettings(cryptoData.length)}>
              {cryptoData.map((client, index) => (
                <div key={index}>
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#transationModel"
                    className="m-1"
                    onClick={() => handleImageClick(client)} 
                  >
                    <img
                      src={client.icon}
                      alt={`Crypto ${index}`}
                      className="img-fluid"
                      width={"300px"}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <p>No Crypto data available</p>
          )}

          <h4 className="mt-5">Fiat Currency</h4>
          {fiatData.length > 0 ? (
            <Slider {...getSliderSettings(fiatData.length)}>
              {fiatData.map((client, index) => (
                <div key={index}>
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#transationModel"
                    className="m-1"
                    onClick={() => handleImageClick(client)} 
                  >
                    <img
                      src={client.icon}
                      alt={`Fiat ${index}`}
                      className="img-fluid"
                      width={"300px"}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <p>No Fiat data available</p>
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <span
            className="spinner-border spinner-border-sm mt-5"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      )}
      <TransationModel selectedData={selectedData} /> 
      <div className="container mt-5">
        <ValueTable />
      </div>
    </>
  );
}

export default Value;
