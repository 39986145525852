import React, { useState } from "react";
import Header from "../common/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import {
  faMoneyBill,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import BankWithdraw from "./BankWithdraw";
import Cryptowidthdraw from "./Cryptowidthdraw";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function C2() {
  const [withdrawMethod, setWithdrawMethod] = useState("bank");
  const kycStatus = useSelector(
    (state) => state.userData.user.kycDoc?.kycStatus
  );

  const renderWithdrawMethod = () => {
    if (withdrawMethod === "bank") {
      if (kycStatus !== "1") {
        return (
          <div className="mt-5 d-flex flex-column align-items-center justify-content-center">
            <div className="text-danger mt-3">
            KYC approval is required to access bank withdrawal.
            </div>
            <div>
              <Link to="/user/verify" className="btn btn-info mt-5 text-white">
                Verify Your KYC
              </Link>
            </div>
          </div>
        );
      }
      return <BankWithdraw />;
    }

    if (withdrawMethod === "crypto") {
      return <Cryptowidthdraw />;
    }
  };

  return (
    <>
      <Header title="Withdraw" />
      <div className="container pb-3">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card w-100 border-info p-4 d-flex justify-content-center align-self-center">
              <div className="card-body">
                <div className="d-flex justify-content-center align-content-center align-self-center">
                  <span className="display-3">
                    <FontAwesomeIcon
                      className="text-info"
                      icon={faMoneyBill}
                      style={{ color: "#16D5FF" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-center align-content-center">
                  <h4 className="mt-2">Available Balance</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card w-100 border-info p-4 d-flex justify-content-center align-self-center">
              <div className="card-body">
                <div className="d-flex justify-content-center align-content-center align-self-center">
                  <span className="display-3">
                    <FontAwesomeIcon
                      className="text-info"
                      icon={faMessage}
                      style={{ color: "#16D5FF" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-center align-content-center">
                  <h4 className="mt-2">In Process</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card w-100 border-info p-4 d-flex justify-content-center align-self-center">
              <div className="card-body">
                <div className="d-flex justify-content-center align-content-center align-self-center">
                  <span className="display-3">
                    <FontAwesomeIcon
                      className="text-info"
                      icon={faMoneyBillTransfer}
                      style={{ color: "#16D5FF" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-center align-content-center">
                  <h4 className="mt-2">Withdraw Balance</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <label htmlFor="withdrawMethod">Choose Withdraw Method</label>
        <select
          className="form-control mt-1 w-25"
          name="withdrawMethod"
          id="withdrawMethod"
          value={withdrawMethod}
          onChange={(e) => setWithdrawMethod(e.target.value)}
        >
          <option value="bank">Bank</option>
          <option value="crypto">Crypto</option>
        </select>

        {renderWithdrawMethod()}
      </div>
    </>
  );
}

export default C2;
