import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changeUserPassword } from "../../../api/Api";

function Password() {
  const userToken = useSelector((state) => state.userData.token);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (credentials.newPassword !== credentials.confirmPassword) {
      toast.error("New password and confirmation do not match.");
      return;
    }

    setLoading(true);

    try {
      const result = await changeUserPassword(
        credentials.oldPassword,
        credentials.newPassword,
        userToken
      );
      if (result && result.status === "success") {
        toast.success(result.message || "Password updated successfully.");
        setCredentials({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        toast.error(result.message || "Failed to update password.");
      }
    } catch (error) {
      toast.error("An error occurred while updating the password.");
    }

    setLoading(false);
  };

  return (
    <>
      <div
        class="modal fade"
        id="changeModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 text-info" id="exampleModalLabel">
                Change Password
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="container pb-3">
              <form onSubmit={handlePasswordSubmit}>
                <div className="row mt-4">
                  <div className="col-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="oldPassword">
                        <span className="fw-semibold ">Current Password</span>
                      </label>
                      <input
                        type="password"
                        className="form-control mt-1"
                        name="oldPassword"
                        value={credentials.oldPassword}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="newPassword">
                        <span className="fw-semibold">New Password</span>
                      </label>
                      <input
                        type="password"
                        className="form-control mt-1"
                        name="newPassword"
                        value={credentials.newPassword}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="confirmPassword">
                        <span className="fw-semibold">Confirm Password</span>
                      </label>
                      <input
                        type="password"
                        className="form-control mt-1"
                        name="confirmPassword"
                        value={credentials.confirmPassword}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row gutters">
                  <div className="col-12">
                    <div className="text-right mt-3 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                          setCredentials({
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-info text-white ms-2"
                        disabled={loading}
                      >
                        {loading ? "Updating..." : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Password;
